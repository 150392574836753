import React from "react";
import './styles.css'
import Header from "../../../components/header/header";
import Banner from "../../../components/banner/banner";
import Footer from "../../../components/footer/footer";
import Step1 from '../../../assets/Step1.png'
import Step2 from '../../../assets/Step2.png'
import Step3 from '../../../assets/Step3.png'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'


function StepByStep(){
    return(
        <>
        <Header />
        <Banner />
        <div className="main-step">
        <div className="espaco"></div>
            <span className="title-step">
                Passo-a-passo de como confirmar sua presença
            </span>
            <div className="espaco"></div>
            <span className="text-step">
                Primeiramente será necessário preencher o campo nome igual ao nome na etiqueta do convite que você recebeu.
                <br/>
                E usar o botão de continuar para dar sequência.
            </span>
            <div className="espaco"></div>
            <img id='step1-img' src={Step1} alt="Contirmação de presença passo 1"></img>
            <div className="espaco"></div>
            <span className="text-step">
                A seguir será necessário selecionar o grupo Adultos, informar um número de contato e um e-mail.
            </span>
            <div className="espaco"></div>
            <img id='step2-img' src={Step2} alt="Contirmação de presença passo 2"></img>
            <div className="espaco"></div>
            <span className="text-step">
                Na pergunta de "Quantos acompanhantes?", deverá informar quantas pessoas vão no total a festa.
            </span>
            <div className="espaco"></div>
            <span className="text-step-ex">
                Por exemplo:
                <br/>
                - Se for um casal, informar que vão dois adultos e preencher com o nome e sobrenome de cada um.
                <br/>
                - Uma família com crianças, informar que vão 2 adultos, o total de crinças com idade de 0 a 5 anos e o total de crianças com 6 a 10 anos.
                Colocar o nome de todos com sobrenome.
                <br/>
                Para finalizar, verificar se preencheu tudo corretamente e clicar no botão de Responder, que vai estar após os campos preenchidos.
            </span>
            <div className="espaco"></div>
            <img id='step3-img' src={Step3} alt="Contirmação de presença passo 3"></img>
            <div className="espaco"></div>
            <span className="text-step">
                Caso tenha ficado alguma dúvida, entre em contato com algum dos noivos, que vamos te ajudar com muita alegria.
            </span>
            <div className="espaco"></div>
            <div className="espaco"></div>
            <Link style={{textDecoration:'none'}} to='/presenca'>
                <FontAwesomeIcon icon={faReply} onClick={window.scrollTo(0, 0)} className="icon-step" />
            </Link>
            <div className="espaco"></div>
            <div className="espaco"></div>
        </div>
        <Footer />
        </>
    )
}

export default StepByStep;