import React from "react";
import './styles.css'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHouse, faChurch, faGift,faChampagneGlasses,faUserCheck, faCommentDots} from '@fortawesome/free-solid-svg-icons'

function Header(){
    return(
        <>
        <header>
            <nav className="nav-header">
                <ul>
                    <Link style={{textDecoration:'none'}} to='/'>
                        <li>Home</li>
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/igreja'>
                         <li>Igreja</li>
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/festa'>
                        <li>Festa</li>
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/presenca'>
                        <li>Confirmação de presença</li>
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/presentes'>
                        <li>Opções de presentes</li>
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/mensagens'>
                        <li>Mensegens</li>
                    </Link>
                </ul>
            </nav>

            <nav className="nav-header-mobile">
                <div className="espaco"></div>
                <div className="icons-header-main">
                    <Link style={{textDecoration:'none'}} to='/'>
                        <FontAwesomeIcon icon={faHouse} className="icon" />
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/igreja'>
                        <FontAwesomeIcon icon={faChurch} className="icon" />
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/festa'>
                        <FontAwesomeIcon icon={faChampagneGlasses} className="icon" />
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/presenca'>
                        <FontAwesomeIcon icon={faUserCheck} className="icon" />
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/presentes'>
                        <FontAwesomeIcon icon={faGift} className="icon" />
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/mensagens'>
                    <FontAwesomeIcon icon={faCommentDots} className="icon" />
                    </Link>
                </div>
                <div className="espaco"></div>
            </nav>
        </header>
        </>
    )
}

export default Header;