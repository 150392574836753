import React from "react";
import './styles.css';
import Header from "../../components/header/header";
import Banner from "../../components/banner/banner";
import FestaImg from "../../assets/ChacaraSantaVitoria.jpeg"
import Footer from "../../components/footer/footer";

function Festa(){
    return(
        <>
        <Header />
        <Banner />
        <div className="festa">
            <p>
                <br/>
                Nossa festa para comemorar esse grande dia será as 21h na Chácara Santa Vitória
                <br/>
                Av. São Paulo, 3445 - Vila Sao Domingos - Sorocaba/SP
            </p>
        </div>

        <div className="festa-mobile">
            <div className="festa-mobile-text">
                <p>
                    <br/>
                    A nossa festa para comemorar esse grande dia
                    <br/>
                    será as 21h na Chácara Santa Vitória
                    <br/>
                    Av. São Paulo, 3445
                    <br/>
                    Vila Sao Domingos - Sorocaba/SP
                </p>
            </div>
        </div>
        <div className="festa-img-map">
            <div className="festa-img">
                <img id='festa-img' src={FestaImg} alt="Entrada Chácara anta Vitória"></img>
            </div>
            <div className="festa-map">
            <iframe id="festa-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.205431969747!2d-47.42106070512872!3d-23.48910895687882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf6068431700e9%3A0x2a8cbdf2b55f416b!2sCh%C3%A1cara%20Santa%20Vict%C3%B3ria!5e0!3m2!1spt-BR!2sbr!4v1713475007508!5m2!1spt-BR!2sbr" 
             allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Endereço no Maps - Chácara Santa Vitória"></iframe>
            </div>
        </div>
        <div id="info-extra">
            <span>
                <br/>
                * O local tem estacionamento
            </span>
        </div>
        <Footer />
        </>
    )
}

export default Festa;