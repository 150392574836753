import React from "react";
import './styles.css'

function Counter(){
    const weddingDate = new Date(2025,1,1,19,0,0).getTime(); //jan = 0 dez = 11
    const now = new Date().getTime();
    const intervalo = weddingDate - now;

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    const dayNum = Math.floor(intervalo/day);
    const hourNum = Math.floor((intervalo%day)/hour);
    const minuteNum = Math.floor((intervalo%hour)/minute);   

    return(
        <>
            <div className="counter-main">
                <div className="counter-area">
                    <div className="counter-day">
                        <span className="counter-num">{dayNum}</span>
                        <span className="counter-text">Dias</span>
                    </div>
                    <div className="counter-day">
                        <span className="counter-num">{hourNum}</span>
                        <span className="counter-text">Horas</span>
                    </div>
                    <div className="counter-day">
                        <span className="counter-num">{minuteNum}</span>
                        <span className="counter-text">Minutos</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Counter;