import React from "react";
import './styles.css';
import Header from "../../components/header/header";
import Banner from "../../components/banner/banner";
import IgrejaImg from "../../assets/IgrejaNossaSenhoraDoCarmo.jpeg"
import Footer from "../../components/footer/footer";

function Igreja(){
    return(
        <>
        <Header />
        <Banner />
        <div className="igreja">
            <p>
                <br/>
                A igreja onde vamos nos casar se chama Capela Nossa Senhora do Carmo.
                <br/>
                A cerimônia vai começar as 19h, por gentileza não se atrasem.
                <br/>
                Rua João Gabriel Mendes, 1670 - Jardim Maria do Carmo - Sorocaba/SP
            </p>
        </div>

        <div className="igreja-text-mobile">
            <div className="igreja-mobile">
                <p>
                    <br/>
                    A igreja onde vamos nos casar se chama
                    <br/>
                    Capela Nossa Senhora do Carmo.
                    <br/>
                    A cerimônia vai começar as 19h,
                    <br/>
                    por gentileza não se atrasem.
                    <br/><br/>
                    Rua João Gabriel Mendes, 1670
                    <br/>
                    Jardim Maria do Carmo - Sorocaba/SP
                </p>
            </div>
        </div>
        

        <div className="igreja-img-map">
            <div className="igreja-img">
                <img id='igreja-img' src={IgrejaImg} alt="Frente da Capela Nossa Senhora do Carmo"></img>
            </div>
            <div className="igreja-map">
                <iframe id="igreja-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.72632955633!2d-47.44442790271412!3d-23.47033413381461!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf5ff9f9ce53cd%3A0x421d102494c7c6a0!2sCapela%20Nossa%20Senhora%20do%20Carmo!5e0!3m2!1spt-BR!2sbr!4v1713472474554!5m2!1spt-BR!2sbr" 
              allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Maps - Endereço Capela Nossa Senhora do Carmo"></iframe>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default Igreja;