import React from "react";
import './styles.css'
import Header from "../../components/header/header";
import Banner from "../../components/banner/banner";
import Footer from "../../components/footer/footer";
import ICaseiImg from '../../assets/iCasei2.png'

function Mensagem(){
    return(
        <>
        <Header />
        <Banner />
        <div className="main-mensagens">
            <span className="msg-title">
                Deixe sua mensagem de carinho para nós
            </span>
            <span className="msg-text">
            Palavras são carinhos doados. Obrigado por nos dar o seu carinho. Iremos lembrar para sempre deste momento tão esperado.
            </span>
            <iframe id="myIframe-msg" src="https://sites.icasei.com.br/eduardo_anna/messages" width="100%" height="900" frameBorder="0"
            title="iCasei - mensagens"/>
        </div>
        <div className="icasei-img-main">
            <div className="icasei-text">
                <span>Função de mensagem por:</span>
            </div>
            <div className="icasei-img-link">
                <a href="https://www.icasei.com.br/" target="_blank">
                    <img id='icasei-img' src={ICaseiImg} alt="iCasei logo img"></img>
                </a>
            </div>
            <div className="espaco"></div>
        </div>
        <Footer />
        </>
    )
}

export default Mensagem;