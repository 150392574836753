import React from "react";
import './styles.css'
import BannerImg from "../../assets/banner.png"
import BannerImg2 from "../../assets/banner2Mobile.png"

function Banner(){
    return(
        <>
        <img id='banner' src={BannerImg} alt="banner"></img>
        <img id='banner2' src={BannerImg2} alt="mobile banner"></img>
        </>
    )
}

export default Banner;
