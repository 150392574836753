import React from "react";
import './styles.css'
import Logo from "../../assets/Logo2.png"
import { Link } from "react-router-dom";

function Footer(){
    return(
     <>
     <footer>
        <div className="footer">
        <Link style={{textDecoration:'none'}} to='/'>
                <img id='logo' src={Logo} alt="EA logo"></img>      
            </Link>
            
            <div className="footer-rights">
                <p id="text-footer">Todos os direitos reservados ©
                    <br/>
                    Desenvolvido por Anna Sanches
                </p>
            </div>

            <nav className="nav-footer">
                <ul className="footer-list">
                    <Link style={{textDecoration:'none'}} to='/'>
                        <li>Home</li>
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/igreja'>
                         <li>Igreja</li>
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/festa'>
                        <li>Festa</li>
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/presenca'>
                        <li>Confirmação de presença</li>
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/presentes'>
                        <li>Opções de presentes</li>
                    </Link>
                    <Link style={{textDecoration:'none'}} to='/mensagens'>
                        <li>Mensegens</li>
                    </Link>
                </ul>
            </nav>

        </div>
     </footer>
     
     </>   
    )
}

export default Footer;