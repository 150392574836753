import React from "react";
import './styles.css'
import Banner from "../../assets/banner2.png"
import BannerImg2 from "../../assets/banner2Mobile.png"
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Counter from "../../components/counter/counter";

function Home(){
    return(
        <>
        <Header />
        <img id='banner' src={Banner} alt="banner home page"></img>
        <img id='banner2' src={BannerImg2} alt="banner mobile"></img>
        <div className="espaco"></div>
        <div className="home-main">
            <div className="home-text">
                <span>
                Seja muito bem-vindo ao nosso cantinho digital.<br/>
                Neste espaço será possível confirmar sua presença para celebrar conosco este dia tão especial.<br/>
                Além disso, encontrará detalhes precisos sobre o horário e local da cerimônia e da festa.<br/>
                Caso deseje nos presentear, disponibilizamos nosso pix e lista de presentes para sua comodidade.
                </span>
            </div>
            <div className="espaco"></div>
            <span className="data-text">1 Fevereiro, 2025</span>
        </div>
        <Counter />
        <div className="espaco"></div>
        <Footer />
        </>
    )
}

export default Home;